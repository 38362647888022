input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type='checkbox'] + label span {
  font-size: 0.7em;
}

input[type='checkbox'] + label::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type='checkbox']:checked + label::before {
  width: 10px;
  top: calc(50% - 5px);
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: translateY(-50%) rotate(45deg);
}

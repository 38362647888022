.SvgView .reset-zoom-btn {
  opacity: 0;
  transform: scale(0);
  /* visibility: hidden; */
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.SvgView .reset-zoom-btn.shown {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

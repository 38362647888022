.App input {
  background-color: #282c34;
  color: white;
  outline: none;
  border: 2px solid white;
  border-radius: 0.3em;
}

.App input.answer {
  padding: 1em;
  font-size: calc(10px + 2vmin);
  margin: 0 5px;
  width: 100%;
  max-width: 98vw;
}

.App input.answer.wrong,
.App input.answer.almost {
  color: #882424;
  border-color: #882424;
}

.App input.answer.gave_up {
  text-decoration: line-through;
}

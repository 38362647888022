*,
::before,
::after {
  box-sizing: border-box;
}
html {
  height: -webkit-fill-available;
}
body {
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  width: 100vw;
  background-color: #282c34;
  color: white;
}
#root {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
input,
button {
  font-family: le-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span[role='img'] {
  padding: 0 0.3em;
}

.d-flex {
  display: flex;
}

.d-flex-v {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
@media (max-width: 575.98px) {
  .hidden-xs {
    display: none;
  }
}

button {
  color: white;
  background: #383d46;
  border: none;
  padding: 0.5em;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  border-radius: 0.3em;
  outline: none;
  transition: background-color 250ms ease-in-out;
  margin: 0 5px;
}
button::-moz-focus-inner {
  border: 0;
}
button.fit-content {
  width: fit-content;
  padding: 0.5em 1em;
}

button:hover,
button:active,
button:focus {
  background: #4b525f;
}

.rocket {
  position: relative;
}
.rocket::before {
  content: '🚀';
  position: absolute;
  transform: scale(1);
  transition: transform 250ms ease-out;
  transition-delay: 150ms;
}
button:active .rocket::before {
  animation: wiggle 1s linear 400ms infinite;
  transform: scale(2);
}
@keyframes wiggle {
  0% {
    transform: scale(2) rotate(0);
  }
  25% {
    transform: scale(2) rotate(-10deg);
  }
  50% {
    transform: scale(2) rotate(0);
  }
  75% {
    transform: scale(2) rotate(10deg);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(0);
  transform-origin: top left;
  transition: transform 250ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: scale(1);
}

.ReactModal__Content--before-close {
  transform: scale(0);
}

.App .question {
  padding: 1em;
}

.question-transition-enter {
  transform: translateX(-100vw);
}
.question-transition-enter-active {
  transform: scale(1);
  transition: transform 350ms ease-in-out;
}

.question-transition-exit {
  transform: translateX(0);
}

.question-transition-exit-active {
  transform: translateX(100vw);
  transition: transform 250ms ease-in-out;
}

.App .message {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Streak {
  position: absolute;
  top: 0;
  left: calc(7vmin + 0.4em);
}
@media (max-width: 575.98px) {
  .Streak {
    left: unset;
    right: 0.4em;
  }
}

.USMap path {
  pointer-events: all;
}
.USMap path:hover,
.USMap path.active {
  opacity: 0.5;
  cursor: pointer;
}
.USMap path.readonly:hover {
  opacity: 1;
  cursor: default;
}

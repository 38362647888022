.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
  font-size: calc(10px + 3vmin);
  font-weight: bold;
}

.App .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 1em;
}
